import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useRouter } from 'next/router';

import 'url-search-params-polyfill';

import { fetcherApiRoute } from '../../services/api/fetcher';
import encodeUri from '../../services/encode-uri';
import { initAmplitude } from '../../services/event-tracking/services';
import getCrossDomainReferrer from '../../services/get-referrer';

// copied from https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
export async function digestId(id) {
  try {
    const msgUint8 = new TextEncoder().encode(id); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest('SHA-512', msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  } catch (error) {
    return '';
  }
}

export default function HocAuthenticate(Component, options = { acceptUrlToken: false }) {
  const WrappedComp = (compProps) => {
    const router = useRouter();
    const { acceptUrlToken, forceLogin } = options;

    const [userData, setUserData] = React.useState(null);
    const [authLoading, setAuthLoading] = React.useState(true);
    const [prefToken, setPrefToken] = React.useState(null);

    const fetchUserProfile = (callback) => {
      fetcherApiRoute('/api/profile', {
        method: 'GET',
      })
        .then(async (fetchedUser) => {
          setUserData(fetchedUser);
          if (fetchedUser) {
            initAmplitude(fetchedUser.association.fairprice);
          }
          datadogRum.setUser({ id: await digestId(fetchedUser.uid) });
          if (typeof callback === 'function') {
            callback();
          }
        })
        .catch(() => {
          setUserData(null);
        });
    };

    React.useEffect(
      () => {
        setAuthLoading(true);
        const urlParams = new URLSearchParams(window.location.search);
        const isSkipLogin = urlParams.get('skip_force_login');
        if (forceLogin && !isSkipLogin) {
          const queryString = encodeUri({
            prompt: true,
            redirectTo: router.asPath,
          });

          window.location.href = `/api/auth/login?${queryString}&entrySource=cmpg_external_integration_hpb`;
          return;
        }

        let requireUserAuthentication = true;
        if (acceptUrlToken) {
          const token = urlParams.get('token');

          if (token) {
            requireUserAuthentication = false;
            setPrefToken(token);

            fetcherApiRoute('/api/subscription', {
              method: 'GET',
              headers: { 'preference-token': token },
            })
              .then(async (result) => {
                datadogRum.setUser({ id: await digestId(result.uid) });
                setUserData(result);
                setAuthLoading(false);
              })
              .catch(() => {
                window.location.href = '/notifications';
              });
          }
        }

        if (requireUserAuthentication) {
          // get referrer link and pass in on to SSO (Auth0)
          // so that it can be consumed later
          // when user comes back to SAM from SSO
          const referrerLink = getCrossDomainReferrer();

          fetcherApiRoute('/api/authenticate')
            .then((result) => {
              window.backTo = getCrossDomainReferrer(result.backTo) || referrerLink;

              fetchUserProfile(() => {
                setAuthLoading(false);
              });
            })
            .catch(() => {
              const queryString = encodeUri({
                backTo: referrerLink,
                redirectTo: router.asPath,
              });

              window.location.href = `/api/auth/login?${queryString}`;
            });
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    return (
      <Component
        userData={userData}
        authLoading={authLoading}
        fetchUserProfile={fetchUserProfile}
        prefToken={prefToken}
        userAuthenticatedByM2m={Boolean(prefToken)}
        {...compProps}
      />
    );
  };

  // won't fix code smell
  return WrappedComp;
}
